<template>
  <div class="live-card">
    <div class="source-tag-container">
      <div
        :style=" data.source === 'video' ? 'background-image: linear-gradient(to bottom, #3023ae, #c86dd7);': 'background-image: linear-gradient(to bottom, #fb9b51, #fbae46);'"
        class="source-tag"
      >
        {{ data.source }}
      </div>
    </div>
    <router-link :to="{ name: 'liveDetail', params: { id: data.id, data: data }}">
      <div class="image live-container">
        <span class="tag">
          <div :class="`tag-name ${data.ended_at === null ? 'online' : ''}`">LIVE</div>
        </span>
        <img
          class="cover-image"
          alt="cover image"
          width="250"
          height="250"
          loading="lazy"
          :src="getThumbnailImage(data.cover_image_url)"
          @error="fallbackThumbnailImage"
        >
      </div>
      <div class="card-content has-text-white">
        <div class="media">
          <div class="media-left">
            <figure class="image is-48x48">
              <img
                class="is-rounded"
                :src="data.user.profile_image_url"
                loading="lazy"
                alt="profile image"
                @error="fallbackProfileImage"
              >
            </figure>
          </div>
          <div class="media-content is-flex-column">
            <h3 class="is-size-5">
              {{ data.title }}
            </h3>
            <p class="is-size-7 is-text-overflow is-block">
              {{ data.user.display_name }}
            </p>
            <div class="media-footer">
              <div class="stat is-flex">
                <span class="icon">
                  {{ formatCreatedTime(data.created_at) }}
                </span>
                <span class="icon">
                  <img
                    src="@/assets/listener.svg"
                    alt="listener icon"
                  >
                  {{ formatNumberCommas(data.current_listeners) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { formatCreatedTime, formatNumberCommas } from '@/utils'

export default {
  name: 'LiveCard',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatCreatedTime (isotime) {
      return formatCreatedTime(isotime)
    },
    formatNumberCommas (number) {
      return formatNumberCommas(number)
    },
    fallbackProfileImage (e) {
      e.target.src = 'https://space.pillowcast.app/image/default/default.svg'
    },
    fallbackThumbnailImage (e) {
      e.target.src = 'https://space.pillowcast.app/image/default/logo.svg'
    },
    getThumbnailImage (url) {
      if (url) {
        return url
      }
      return 'https://space.pillowcast.app/image/default/logo.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
.live-card {
  width: 100%;
  color: #ffffff;
  background-color: rgba(21, 24, 35, 0.25);
  border: 1px solid #29394d;
  border-radius: 8px;
  padding: 14px;
  transition: background-color 0.25s ease;
  -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) ease-in;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) ease-in;

  .live-container {
    overflow: hidden;
  }

  // &:hover {
    // border: 1px solid #ccc;
    // background-color: #233d5a;
    // -webkit-transition: background-color 250ms linear;
    // -ms-transition: background-color 250ms linear;
    // transition: background-color 250ms linear;
    // box-shadow: rgba(136, 153, 166, 0.2) inset 0 0 30px, rgba(136, 153, 166, 0.5) inset 0 0 10px, rgba(136, 153, 166, 0.2) 0px 0px 15px, rgba(136, 153, 166, 0.5) 0px 0px 3px 1px;
  // }
}

@keyframes glowRotate {
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  0% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

.cover-image {
  border-radius: 8px;
  transition: transform .25s ease;
  object-fit: cover;
  @media screen and (min-width: 1023px) {
    height: 200px;
  }
  @media screen and (max-width: 1023px) {
    height: 180px;
  }
}

.source-tag-container {
  display: flex;
  justify-content: flex-end;
}

.source-tag {
  align-items: center;
  border-radius: 15px;
  color: #fff;
  display: inline-flex;
  font-size: .75rem;
  height: 2em;
  margin-bottom: 0.5rem;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
}

.tag {
  z-index: 1;
  padding: 10px;
  display: flex;
  position: absolute;
  justify-content: flex-start;
  margin-bottom: auto;
  top: 10px;
  .tag-name {
    font-size: 0.875rem;
    padding: 2px 10px;
    border-radius: 8px;
    max-width: 100%;
    margin: 0;
    transition-delay: 1s;
    font-weight: 500;
    height: 26px;
    filter: brightness(0.5) saturate(0.5);
    color: #ffffff;
    background-color: #ff344d;
    &.online {
      text-shadow: 0 0 1px #fff;
      color: #ffffff;
      filter: brightness(1.1);
      background-color: #ff344d;
      box-shadow:0 0 5px #ff344d, 0 0 40px #ff344d, 0 0 120px #ff344d;
      animation: glow 0.1s ease-in-out infinite alternate;
    }
    @media screen and (max-width: 767px) {
      margin: 5px auto;
    }
  }
}

.card-content {
  padding: 14px 0 0 0;
  h3 {
    line-height: 1.25rem;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .media-footer {
    img {
      margin-right: 3px;
      opacity: .75;
    }
    .stat {
      font-size: 0.75rem;
      opacity: 0.75;
      align-items: center;
      margin-bottom: 0.5rem;
      @media screen and (max-width: 767px) {
        font-size: 0.75rem;
        margin: 0;
      }
      .icon {
        width: auto;
        margin-right: 10px;
        img {
          margin-right: 5px;
        }
      }
    }
  }
}

@keyframes glow {
  from {
    filter: brightness(1.1);
  }
  to {
    filter: brightness(1.2);
  }
}
</style>
